var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"EditPrf"}},[_c('div',{staticClass:"form-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(
          _vm.added.name,
          _vm.added.code,
          _vm.added.main,
      
        )}}},[_c('div',{staticClass:"grup-form dflx"},[_c('div',{staticClass:"boxform"},[_c('label',{staticClass:"name",attrs:{"for":"name"}},[_vm._v("Nombre ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.added.name),expression:"added.name"}],attrs:{"type":"text","id":"name","name":"name","required":""},domProps:{"value":(_vm.added.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.added, "name", $event.target.value)}}})]),_c('div',{staticClass:"boxform"},[_c('label',{staticClass:"code",attrs:{"for":"code"}},[_vm._v("Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.added.code),expression:"added.code"}],attrs:{"type":"color","id":"code","name":"code","required":""},domProps:{"value":(_vm.added.code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.added, "code", $event.target.value)}}})])]),_c('div',{staticClass:"dflx"},[_c('p',{staticClass:"btnRegresar",on:{"click":function($event){return _vm.wait()}}},[_vm._v("Regresar")]),_c('button',{staticClass:"alta",attrs:{"type":"submit"}},[_vm._v("Editar")])]),(_vm.status != '')?_c('div',{staticClass:"status_messages"},[(_vm.status == 'success')?_c('div',{staticClass:"msg msg_success"},[_c('p',[_vm._v(_vm._s(_vm.message))])]):_vm._e(),(_vm.status == 'error')?_c('div',{staticClass:"msg msg_error"},[_c('p',[_vm._v(_vm._s(_vm.message))])]):_vm._e()]):_vm._e()])]),(_vm.viewModal)?_c('div',{staticClass:"modal_view"},[_c('div',{attrs:{"id":"modal_edit"}},[_vm._m(0),_c('div',{staticClass:"modal_edit_btns dflx"},[_c('p',{staticClass:"otro",on:{"click":function($event){return _vm.wait()}}},[_vm._v("Aceptar")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body dflx"},[_c('h3',[_vm._v("Color actualizado")]),_c('img',{attrs:{"src":require("../../assets/add.png"),"alt":"icono alerta"}})])
}]

export { render, staticRenderFns }