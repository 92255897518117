<template >
    <div id="EditBlo">
             
               <div class="form-content">
               <form @submit.prevent="
                submit(
                  added.title,   added.id_category, added.body,  images,  added.statusproject, added.url
                )
              ">

              
                <p class="subtitle">Visibilidad</p>
                   <div class="dflx">
                          <p :class="{statusVisible: added.statusproject =='VISIBLE'}" class="btnStatus" @click="added.statusproject='VISIBLE'" >VISIBLE</p><p  :class="{statusVisible: added.statusproject =='OCULTO'}" class="btnStatus"  @click="added.statusproject='OCULTO'">OCULTO</p>
                   </div>

                    

                   

                     


                    <div class="grup-form dflx">
                         <div class="boxform">
                            <label for="title" class="title">Titulo </label>
                            <input v-model="added.title" type="text" id="title" name="title"   required >
                         </div>
                      
                         <div class="boxform">
                            <label for="url" class="url">Url </label>
                            <input v-model="added.url" type="text" id="url" name="url"   required >
                         </div>
                         
                       
                       
                    </div>

                   

                    

                 

                






                          <div class="grup-form dflx">
         
                        
                       

                         <div class="boxform">
                            <label for="id_category" class="id_category">Categoría </label>
                          
                              <div class="select-container">
                                <input
                                    type="checkbox"
                                    name="select-ch3"
                                    id="select-ch3"
                                    v-model="active3"
                                    :true-value="true"
                                    :false-value="false"
                                />
                                <label for="select-ch3">
                                    <div id="select-role" :class="{ rounded: active3 }">
                                    {{added.category_name }}
                                    </div>
                                </label>
                                <div  class="options-cont" @click="closeSelect3()">
                                    <div v-for="(cat, key) in categorias" :key='key' class="option-btn" @click="added.id_category = cat.id, added.category_name =cat.name">{{cat.name}}</div>
                                </div>
                               
                                </div>
                        </div>

                       
                       
         
                     </div>

             

            

            <!-- bloques -->
   <p class="subtitle">Bloques agregados</p>
              <hr class="hrhd">
     
        
        <div class="bloques-cont">
                   <div class="dflx header-bc">
                    <div class="col1">
                        <p>ordenar</p>
                    
                    </div>
                    <div class="col2">
                            <p> Tipo</p>
                            
                    </div>
                    <div class="col3 cl3t">
                            <p> Contenido</p>
                            
                    </div>
                    <div class="col4">
                           <p>Eliminar</p>
                    </div>
                   </div>
                <div v-for="(bloq, key) in added.body" :key="key" class="bloq-item dflx">
                    <div class="col1">
                        
                        <h2 v-if="key !=0" @click="UpItem(bloq, key)" class="cp">	&#9650;</h2>
                        <h2 v-if="key < (added.body.length-1)" @click="DownItem(bloq,key)" class="cp">	&#9660;</h2>
                    </div>
                    <div class="col2">
                           <p  v-if="bloq.tipo !='titulo_centrado' && bloq.tipo !='subtitulo_centrado'">{{bloq.tipo}}</p>
                                     <p  v-else-if="bloq.tipo =='titulo_centrado'">título <br> centrado</p>
                             <p  v-else-if="bloq.tipo =='subtitulo_centrado'">subtítulo <br> centrado</p>


                            
                    </div>
                    <div class="col3"> <!-- contenido del bloque  -->
                        <div v-if="bloq.tipo =='texto'" v-html="bloq.texto"></div>
                        <div v-if="bloq.tipo =='video'" v-html="bloq.video"></div>
                        <div v-if="bloq.tipo =='reto'">
                            <p><strong>{{bloq.reto.texto}}</strong></p>
                            <div v-html="bloq.reto.parrafo"></div>
                        </div>
                         <div v-if="bloq.tipo =='titulo_centrado'">
                            <p> <strong>{{bloq.titulo_centrado}}</strong> </p>
                        </div>

                          <div v-if="bloq.tipo =='subtitulo_centrado'">
                            <p> {{bloq.subtitulo_centrado}} </p>
                        </div>

                        <div  v-if="bloq.tipo =='slider'">
                            <img v-for="(img , key) in bloq.slider" :key="key" :src="srcImg(img)" class="imgslider" alt="imagen bloque">
                        </div>

                         <div  v-if="bloq.tipo =='galeria'">
                            <img v-for="(img , key) in bloq.galeria" :key="key" :src="srcImg(img)" class="imgslider" alt="imagen bloque">
                        </div>


                        <img v-if="bloq.tipo =='imagen'" :src="srcImg(bloq.imagen)" alt="imagen bloque">
                         <img v-if="bloq.tipo =='paleta'" :src="srcImg(bloq.imagen)" alt="imagen bloque">
                        <div v-if="bloq.tipo =='caja'" class="dflx">
                           
                            <div class="caja1">
                                <p v-if="bloq.caja[0].tipo=='texto'" v-html="bloq.caja[0].texto">  </p>
                                  <img v-if="bloq.caja[0].tipo =='imagen'" :src="srcImg(bloq.caja[0].imagen)" alt="imagen bloque">
                                  <div v-if="bloq.caja[0].tipo =='reto'">
                                    <p> <strong>{{bloq.caja[0].reto.texto}}</strong> </p>
                                     <div v-html="bloq.caja[0].reto.parrafo"></div>
                                </div>
                                 <div v-if="bloq.caja[0].tipo =='titulo_centrado'">
                                    <p> <strong>{{bloq.caja[0].titulo_centrado}}</strong> </p>
                                </div>
                                  <div v-if="bloq.caja[0].tipo =='subtitulo_centrado'">
                                    <p> {{bloq.caja[0].subtitulo_centrado}} </p>
                                </div>

                            </div>
                             <h2 class="cp" @click="CambiarOrdenCaja(bloq, key)">	&#11020;</h2>
                            <div class="caja2">
                                <p v-if="bloq.caja[1].tipo=='texto'" v-html="bloq.caja[1].texto">  </p>
                                  <img v-if="bloq.caja[1].tipo =='imagen'" :src="srcImg(bloq.caja[1].imagen)" alt="imagen bloque">
                                    <div v-if="bloq.caja[1].tipo =='reto'">
                                    <p> <strong>{{bloq.caja[1].reto.texto}}</strong></p>
                                    <div v-html="bloq.caja[1].reto.parrafo"></div>
                                </div>
                                  <div v-if="bloq.caja[1].tipo =='titulo_centrado'">
                                    <p> <strong>{{bloq.caja[1].titulo_centrado}}</strong> </p>
                                </div>
                                  <div v-if="bloq.caja[1].tipo =='subtitulo_centrado'">
                                    <p> {{bloq.caja[1].subtitulo_centrado}} </p>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                    <div class="col4">
                           <h2 class="cp"  @click=" showModalDelete2(bloq,key)">&#10006;</h2>
                    </div>
                </div>
                <p class="snu" v-if="added.body.length ==0">Sin contenido agregado al cuerpo del proyecto</p>
        </div>
            <!-- fin bloques -->


                     <!-- Añadir bloques -->
                   <p class="subtitle">Cuerpo de la entrada</p>
        <hr class="hrhd">
      

      <div class="opciones-bloque">
            
            <div class="dflx optionsbl">
                

                         <div class="boxform">
                            <label for="role" class="role">Tipo de bloque </label>
                           <div class="select-container">
              <input
                type="checkbox"
                name="select-ch"
                id="select-ch"
                v-model="active"
                :true-value="true"
                :false-value="false"
              />
              <label for="select-ch">
                <div id="select-role" :class="{ rounded: active }">
                  {{ tipo }}
                </div>
              </label>
              <div class="options-cont" @click="closeSelect()">
                <div class="option-btn" @click="tipo = 'texto'">Texto</div>
                 <div class="option-btn" @click="tipo = 'imagen'">Imagen</div>
                 <!--  <div class="option-btn" @click="tipo = 'slider'">Slider</div> 
                    <div class="option-btn" @click="tipo = 'paleta'">Paleta</div>
                      <div class="option-btn" @click="tipo = 'titulo_centrado'">Titulo centrado</div>
                   <div class="option-btn" @click="tipo = 'subtitulo_centrado'">Subtítulo centrado</div>
                    <div class="option-btn" @click="tipo = 'reto'">reto</div>
                 -->
                    <div class="option-btn" @click="tipo = 'galeria'">Galeria</div>
                 
                  <div class="option-btn" @click="tipo = 'caja'">Caja</div>
              
                    <div class="option-btn" @click="tipo = 'video'">Video</div>
              </div>
            </div>
                        </div>


                <div  @click="guardarBloque(tipo)"  class="btn_añadir2 dflx mtbtn">
                                <p > agregar bloque</p>  <img src="../../assets/add.png" alt="">
               </div>
            </div>   

                  

             <vue-editor v-if="tipo=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto"
                >
              </vue-editor> 

               <div v-if="tipo=='video'">
                     <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="video" class="video">Insertar iFrame</label>
                            <input v-model="video" type="text" id="video" name="video">
                        </div>
                    </div>

                        <vue-editor 
                                id="editor"
                                class="quill-editor"
                                :editorToolbar="toolbar()"
                                v-model="video"
                                >
                            </vue-editor> 
                </div>



                    <div class="" v-if="tipo =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                      <div class="" v-if="tipo =='slider'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">

                              <p @click="añadirSlider()" class="btnRegresar">Agregar</p>

                                <div  class="dflx">
                                    <img  v-for="(item, index) in slider" :key="index" :src="srcImg(item)" class="imgslider mlf" alt="imagen bloque">
                                </div>
                    </div>
                    <div class="" v-if="tipo =='galeria'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">

                              <p @click="añadirGaleria()" class="btnRegresar">Agregar</p>

                             
                               <div  class="dflx">
                                    <img  v-for="(item, index) in galeria" :key="index" :src="srcImg(item)" class="imgslider mlf" alt="imagen bloque">
                                </div>
                    </div>


                    <div class="" v-if="tipo =='paleta'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                    <div v-if="tipo=='reto'">
                       <div class="grup-form dflx">
                        <div class="boxform">
                            <label for="reto" class="reto">Título</label>
                            <input v-model="reto" type="text" id="reto" name="reto">
                        </div>
                        <div class="boxform">
                          <label for="parrafo" class="parrafo">parrafo</label>
                            <input v-model="parrafo" type="text" id="parrafo" name="parrafo">
                        </div>
                    </div>
                    </div>

                    <div v-if="tipo=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado" class="titulo_centrado">Titulo centrado</label>
                            <input v-model="titulo_centrado" type="text" id="titulo_centrado" name="titulo_centrado">
                        </div>
                    </div>
                    </div>

                     <div v-if="tipo=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado" class="subtitulo_centrado">Titulo centrado</label>
                            <input v-model="subtitulo_centrado" type="text" id="subtitulo_centrado" name="subtitulo_centrado">
                        </div>
                    </div>
                    </div>
                
                <div v-if="tipo == 'caja'">
                     <p class="subtitle">Caja 1</p>
                    
                        <div class="dflx btnsgrp">
                          
                            <p class="btnopciones"  :class="{ 'btnoact' : caja1=='texto'}" @click="caja1 = 'texto'">Texto</p>
                            <p class="btnopciones" :class="{ 'btnoact' : caja1=='imagen'}" @click="caja1 = 'imagen'">Imagen</p>
                           <!-- <p class="btnopciones" @click="caja1 = 'titulo_centrado'">Titulo centrado</p>
                             <p class="btnopciones" @click="caja1 = 'subtitulo_centrado'">Subtítulo centrado</p>
                            <p class="btnopciones" @click="caja1 = 'reto'">reto</p> -->
                        </div>

                         <vue-editor v-if="caja1=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto"
                >
              </vue-editor> 

             
              <div v-if="caja1=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado" class="titulo_centrado">Titulo centrado</label>
                            <input v-model="titulo_centrado" type="text" id="titulo_centrado" name="titulo_centrado">
                        </div>
                    </div>
                    </div>

                     <div v-if="caja1=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado" class="subtitulo_centrado">Subtítulo centrado</label>
                            <input v-model="subtitulo_centrado" type="text" id="subtitulo_centrado" name="subtitulo_centrado">
                        </div>
                    </div>
                    </div>


                    <div v-if="caja1=='reto'">
                       <div class="grup-form dflx">
                        <div class="boxform">
                            <label for="reto" class="reto">Título</label>
                            <input v-model="reto" type="text" id="reto" name="reto">
                        </div>
                        <div class="boxform">
                          <label for="parrafo" class="parrafo">parrafo</label>
                            <input v-model="parrafo" type="text" id="parrafo" name="parrafo">
                        </div>
                    </div>
                    </div>

                    <div class="" v-if="caja1 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                    <p class="subtitle mtp">Caja 2</p>

                         <div class="dflx btnsgrp">
                          
                            <p class="btnopciones" :class="{ 'btnoact' : caja2=='texto'}" @click="caja2 = 'texto'">Texto</p>
                            <p class="btnopciones" :class="{ 'btnoact' : caja2 ='imagen'}" @click="caja2 = 'imagen'">Imagen</p>
                          <!--  <p class="btnopciones" @click="caja2 = 'titulo_centrado'">Titulo centrado</p>
                               <p class="btnopciones" @click="caja2 = 'subtitulo_centrado'">Subtítulo centrado</p>
                            <p class="btnopciones" @click="caja2 = 'reto'">reto</p> -->
                        </div>

                         <vue-editor v-if="caja2=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto2"
                >
              </vue-editor> 
               <div v-if="caja2=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado2" class="titulo_centrado2">Titulo centrado</label>
                            <input v-model="titulo_centrado2" type="text" id="titulo_centrado2" name="titulo_centrado2">
                        </div>
                    </div>
                    </div>

                     <div v-if="caja2=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado2" class="subtitulo_centrado2">Subtítulo centrado</label>
                            <input v-model="subtitulo_centrado2" type="text" id="subtitulo_centrado2" name="subtitulo_centrado2">
                        </div>
                      </div>
                    </div>


                    <div v-if="caja2=='reto'">
                       <div class="grup-form dflx">
                        <div class="boxform">
                            <label for="reto2" class="reto2">Título</label>
                            <input v-model="reto2" type="text" id="reto2" name="reto2">
                        </div>
                        <div class="boxform">
                          <label for="parrafo2" class="parrafo2">parrafo</label>
                            <input v-model="parrafo2" type="text" id="parrafo2" name="parrafo2">
                        </div>
                    </div>
                    </div>
                     <div class="" v-if="caja2 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg3"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg3">   {{name_imagen2}}</label>
                        </div> 
                            
                            <input @change="previewFiles3" type="file" id="uploadimg3" name="uploadimg3"    required class="fileinput">
                    </div>



                </div>


               
              

      
      </div>
                     

                  
                
                   
                      <div class="dflx nav-btns">
                               <p @click="wait()" class="btnRegresar">Regresar</p>
                               <button class="alta" type="submit">Guardar </button>
                        </div>

                    
                        
            </form>


                     
          


        </div>

               

                
            <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
                <div class="body dflx">
                     
                           <h3>Proyecto actualizado </h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_edit_btns dflx">
                <p @click="wait()" class="otro">Aceptar</p> <!-- <p  @click="back()" class="inicio">Inicio</p>-->
            </div>
          </div>
        </div> 

       

         <div v-if="viewModal3" class="modal_view">
           
            <div id="modal_delete">
                <div class="body">
                     <img src="../../assets/alert.png" alt="icono alerta">
            <h3 >¿Estás seguro de  <span>eliminar <br> este bloque?</span></h3>
                </div>
           
            <div class="modal_del_btns dflx">
                <p @click="DelBloq()">Aceptar</p>   <p  @click="closeModal3()" >Cancelar</p>
            </div>
          </div>
        </div> 


        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>


                    
    </div>
</template>
<script>


import { VueEditor } from "vue2-editor";
import {mapActions} from 'vuex';
import {url, endpointCodes} from '../../global/index'; 
export default {
    name:'EditBlo',
    components: { 
     VueEditor, 
    },
    data(){
        return{
           status:'',
           message:'',
            active: false,
          
            
           
            
            
            
            title:"", 
            descrp:"", 

            id_category:"", 
            catname:"",

            file1:null,
            archivo:'No se eligió archivo',

             file2:null,
            archivo2:'No se eligió archivo',

             file3:null,
            archivo3:'No se eligió archivo',
             file4:null,
            archivo4:'No se eligió archivo',
         
            formato:"", 
          
            images:[], //imagenes agregadas
 
            //bloques
            body:[],
            tipo:'texto',
            caja1:'texto',
            caja2:'texto',
            image:null,
            name_imagen:'',
            titulo_centrado:'',
            subtitulo_centrado:'',
            video:'',
            reto:'',
            parrafo:'',
            texto: "",

            image2:null,
            name_imagen2:'',
            titulo_centrado2:'',
            subtitulo_centrado2:'',
            video2:'',
            reto2:'',
            parrafo2:'',
            texto2: "",

            slider:[],
            galeria:[],


            editorOption: { 
                modules: {
                           
                        },
                        
                        theme: 'snow'
                },

          

           //selects

            active: false,
            active2: false,
            active3: false,
            active4: false,
            active5: false,
            active6: false,
            active7: false,

            viewModal:false,
            viewModal2:false,
            viewModal3:false,
            itemdel:'',
            bloq:'',
            key:'',


            search_value:'',
           updsearchcli:'',
          
        }
    },

  
    created(){
       
         this.getAllInfoCtp('project_category')
    },
   
    computed:{
        added(){
            return this.$store.getters["project/getAdded"]
        },
    
         categorias() {
                return this.$store.getters["project_category/data"];
        },
        
    },
    methods:{
       
        ...mapActions('project_category', ['getAllInfoCtp']),
       
        ...mapActions('project', ['setAddedPrj']),
        ...mapActions('project', ['setHistoryOptionPrj']),
        ...mapActions("project", ["getInfoByIdPrj"]),

         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },


             submit: async function(  title,   id_category, body,  images ,  statusproject, url){
                 this.status =''
                 this.message =''
                
                body = JSON.stringify(body)
             
                images = JSON.stringify(images)


                   let item ={
                        "id":this.added.id,
                         title,   id_category, body,  images ,  statusproject, url
                       }

                    

                        let result = await this.$store.dispatch("project/editItemPrj",  {option:'project', item: item});
                    
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.showModal()
                            
                        } 
                   
                   
             

            
    },

     añadirSlider: async function(){
            

            if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const img of this.slider){
                        array.push(img)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                        
                            array.push(result.fileName)
                            this.slider = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }


        }
        ,

          añadirGaleria: async function(){
            

            if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const img of this.galeria){
                        array.push(img)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                        
                            array.push(result.fileName)
                            this.galeria = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }


        }
        ,


   closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },

    
     delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
             this.status2=""
            this.message2=""
        },


      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedPrj('')
        this.setHistoryOptionPrj('Default')
    },

     closeModal2:function(){
        this.viewModal2=false;
        this.itemdel=''
       
    },
     closeModal3:function(){
        this.viewModal3=false;
        this.itemdel=''
       
    },


    showModalDelete (id){
            this.itemdel =id
            this.viewModal2 = true;
        },

        showModalDelete2 (bloq, key){
            this.bloq =bloq
            this.key = key
            this.viewModal3 = true;
        },


 


    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    },



    //inputs imagen y toolbars
    toolbar:function(){
             if(this.tipo == 'video'){
                  return  [
                        [  'video' ], 
                    ];
             }else if(this.tipo =='imagen'){
                  return  [
                        [  'image' ], 
                    ];
             }else{
                 return  [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
               // [ 'link','image' ,'video' ],          // add's image support
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
             }
            },
             previewFiles(e) {
              
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image= files[0];
              
            this.name_imagen = this.image.name
        },

           previewFiles3(e) {
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image2= files[0];
           
            this.name_imagen2 = this.image2.name
        },


        previewFiles2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file1= files[0];
            this.archivo = this.file1.name
        },

          previewFiles4(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file2= files[0];
            this.archivo2 = this.file2.name
        },

         previewFiles5(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file3= files[0];
            this.archivo3 = this.file3.name
        },
          previewFiles6(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file4= files[0];
            this.archivo4 = this.file4.name
        },

           srcImg:function (img){
                let src = `${url}/${endpointCodes.get}/project-img/${img}`
                
                //this.status_img(img)
                 
                return src
        },
           status_img:async  function (img){
                 let payload ={
                    option:'colaboradores',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                    if(response.data.status=='error'){
                        this.statusimg='error'
                    }else{
                        this.statusimg='success'
                    }
                }catch(err){
                    this.statusimg='error'
                }

               
               
    },


    //edit cuerpo project
     UpItem: function(item, key){
        let bloques =this.added.body;
      
        let anterior = key-1
        let actual = key
        let item_actual =item
        let item_anterior = bloques[key-1]

        let array = []
        
        for(var i=0; i<bloques.length;i++){
          if(i == actual){
            array.push(item_anterior)
          }else if(i==anterior){
            array.push(item_actual)
          }else{
            array.push(bloques[i])
          }
        }

        this.added.body =array
    },
    DownItem: function(item, key){
      let bloques =this.added.body;
      
      let siguiente = key+1
      let actual = key
      let item_actual =item
      let item_siguiente = bloques[key+1]

       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i == actual){
          array.push(item_siguiente)
        }else if(i==siguiente){
          array.push(item_actual)
        }else{
          array.push(bloques[i])
        }
      }

      this.added.body =array

    },
    DelBloq: async function(){

    let bloq = this.bloq
    let key = this.key
      let bloques =this.added.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }
      }

      this.added.body =array

      //! eliminar la imagen si es de tipo imagen.
      if(bloq.tipo=='imagen' || bloq.tipo=='paleta'){
       
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.imagen});
      }

       if(bloq.tipo=='slider' ){
       
         for (const img of bloq.slider) {
              let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:img});
         }
      } 

       if(bloq.tipo=='galeria' ){
       
         for (const img of bloq.galeria) {
              let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:img});
         }
      } 



      if(bloq.tipo =='caja'){
          if(bloq.caja[0].tipo =='imagen'){
         
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.caja[0].imagen});
         }

          if(bloq.caja[1].tipo =='imagen'){
         
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.caja[1].imagen});
         }
      }


      this.viewModal3 = false;
      this.bloq =''
      this.key =''

    },
    CambiarOrdenCaja: function(bloq, key){
        let bloques =this.added.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }else{
          let model  ={ 'caja': [bloq.caja[1], bloq.caja[0]] , 'tipo': 'caja'}
          array.push(model)
        }
      }

      this.added.body =array
    },
        
   
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },
     closeSelect2() {
      this.active2 = false;
      document.getElementById("select-ch2").checked = false;
    },
      closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },
      closeSelect4() {
      this.active4 = false;
      document.getElementById("select-ch4").checked = false;
    },
     closeSelect5() {
      this.active5 = false;
      document.getElementById("select-ch5").checked = false;
    },
     closeSelect6() {
      this.active6 = false;
      document.getElementById("select-ch6").checked = false;
    },
     closeSelect7() {
      this.active7 = false;
      document.getElementById("select-ch7").checked = false;
    },

    // bloques
        async guardarBloque(tipo){
            if(tipo=='texto'){
                if(this.texto==undefined || this.texto=='' || this.texto==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'texto': this.texto, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.texto =""
                }


            }else if(tipo=='video'){
                if(this.video==undefined || this.video=='' || this.video==null){
                    this.status='error'
                    this.message='Agrega un video.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'video': this.video, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.video =""
                }

            }else if(tipo=='slider'){
                if(this.slider =='' || this.slider == undefined || this.slider == null || this.slider.length ==0){
                    this.status='error'
                    this.message='El slider aun no contiene imagenes.'
                   
                    this.delStatus()
                }else{
                     let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'slider': this.slider, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.slider =[];
                    this.image=null
                    this.name_imagen =''
                }

            }else if(tipo=='galeria'){
                if(this.galeria =='' || this.galeria == undefined || this.galeria == null || this.galeria.length ==0){
                    this.status='error'
                    this.message='El galeria aun no contiene imagenes.'
                   
                    this.delStatus()
                }else{
                     let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'galeria': this.galeria, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.galeria =[];
                    this.image=null
                    this.name_imagen =''
                }

            }else if(tipo=='imagen'){
               if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                         let model ={'imagen': result.fileName, 'tipo': tipo}
                            array.push(model)
                            this.added.body = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }
            }else if(tipo=='paleta'){
               if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                         let model ={'imagen': result.fileName, 'tipo': tipo}
                            array.push(model)
                            this.added.body = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }
            }else if(tipo=='reto'){
               if(this.reto==undefined || this.reto=='' || this.reto==null || this.parrafo==null || this.parrafo==null || this.parrafo==null){
                    this.status='error'
                    this.message='reto o parrafo vacia.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'reto': {'texto':this.reto, 'parrafo':this.parrafo}, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.reto =""
                    this.parrafo =""
                }
            }else if(tipo=='titulo_centrado'){
               if(this.titulo_centrado==undefined || this.titulo_centrado=='' || this.titulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'titulo_centrado': this.titulo_centrado, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.titulo_centrado =""
                    
                }
            }else if(tipo=='subtitulo_centrado'){
               if(this.subtitulo_centrado==undefined || this.subtitulo_centrado=='' || this.subtitulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                    let model ={'subtitulo_centrado': this.subtitulo_centrado, 'tipo': tipo}
                    array.push(model)
                    this.added.body = array
                    this.subtitulo_centrado =""
                    
                }
            }else if(tipo=='caja'){
                let item1;
                let item2;
                let statusC1='success'
                let statusC2='success'
                if(this.caja1=='texto'){

                    if(this.texto==undefined || this.texto=='' || this.texto==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC1='error'
                    this.delStatus()
                    }else{
                       

                        item1 ={'texto': this.texto, 'tipo': this.caja1}

                       
                       
                    }
                }else if(this.caja1=='titulo_centrado'){
                    if(this.titulo_centrado==undefined || this.titulo_centrado=='' || this.titulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacia.'
                      statusC1='error'
                    this.delStatus()
                        }else{
                        

                            item1 ={'titulo_centrado': this.titulo_centrado, 'tipo': this.caja1}
                        
                            
                        }

                }else if(this.caja1=='subtitulo_centrado'){
                    if(this.subtitulo_centrado==undefined || this.subtitulo_centrado=='' || this.subtitulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacia.'
                      statusC1='error'
                    this.delStatus()
                        }else{
                        

                            item1 ={'subtitulo_centrado': this.subtitulo_centrado, 'tipo': this.caja1}
                        
                            
                        }

                }else if(this.caja1=='reto'){
                            if(this.reto==undefined || this.reto=='' || this.reto==null || this.parrafo==null || this.parrafo==null || this.parrafo==null){
                            this.status='error'
                            this.message='reto o parrafo vacia.'
                            statusC1='error'
                            this.delStatus()
                        }else{
                        
                         item1 ={'reto': {'texto':this.reto, 'parrafo':this.parrafo}, 'tipo': this.caja1}
                        
                        }
                }else if(this.caja1 =='imagen'){
                          if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC1='error'
                    this.delStatus()
                }else{
                  

                    
                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                     
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array =[]
                         for (const img of this.images) {
                            array.push(img)
                         }
                         array.push(result.fileName)
                        this.images = array
                        item1 ={'imagen':result.fileName, 'tipo': this.caja1}
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                          statusC1='error'
                        //enviar a borrar la imagen
                        
                    }
                    



                }
                }else{
                    statusC1='error'
                }

                if(this.caja2=='texto'){
                    if(this.texto2==undefined || this.texto2=='' || this.texto2==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC2='error'
                    this.delStatus()
                    }else{
                       

                        item2 ={'texto': this.texto2, 'tipo': this.caja2}
                       
                       
                    }
                }else if(this.caja2=='titulo_centrado'){
                    if(this.titulo_centrado2==undefined || this.titulo_centrado2=='' || this.titulo_centrado2==null ){
                        this.status='error'
                        this.message='Titulo centrado vacia.'
                        statusC2='error'
                        this.delStatus()
                        }else{
                        

                            item2 ={'titulo_centrado': this.titulo_centrado2, 'tipo': this.caja2}
                        }
                        
                            
                }else if(this.caja2=='subtitulo_centrado'){
                    if(this.subtitulo_centrado2==undefined || this.subtitulo_centrado2=='' || this.subtitulo_centrado2==null ){
                        this.status='error'
                        this.message='Subtítulo centrado vacia.'
                        statusC2='error'
                        this.delStatus()
                        }else{
                            item2 ={'subtitulo_centrado': this.subtitulo_centrado2, 'tipo': this.caja2}
                        }
                        
                            
                }else if(this.caja2=='reto'){
                            if(this.reto2==undefined || this.reto2=='' || this.reto2==null || this.parrafo2==null || this.parrafo2==null || this.parrafo2==null){
                            this.status='error'
                            this.message='reto o parrafo vacia.'
                            statusC2='error'
                            this.delStatus()
                        }else{
                        
                         item2 ={'reto': {'texto':this.reto2, 'parrafo':this.parrafo2}, 'tipo': this.caja2}
                        
                        }
                }else if(this.caja2 =='imagen'){
                          if(this.name_imagen2==undefined || this.name_imagen2=='' || this.name_imagen2==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC2='error'
                    this.delStatus()
                }else{
                  

                    
                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                     
                
                    data.append('image', this.image2);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                 
                  
                   
                    if(result.status=='success'){
                        
                         let array =[]
                         for (const img of this.images) {
                            array.push(img)
                         }
                         array.push(result.fileName)
                        this.images = array

                        item2 ={'imagen': result.fileName, 'tipo': this.caja2}
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        statusC2='error'
                        //enviar a borrar la imagen
                        
                    }
                    



                }
                }else{
                    statusC2='error'
                }

                if(statusC1 == 'success' && statusC2 =='success'){
                    let model  ={ 'caja': [item1, item2] , 'tipo': tipo}


                     let array =[];
                    for(const bloque of this.added.body){
                        array.push(bloque)
                    }

                   
                    array.push(model)
                    this.added.body = array
                    this.texto=""
                     this.texto2=""
                    this.titulo_centrado =""
                     this.titulo_centrado2 =""
                       this.subtitulo_centrado =""
                     this.subtitulo_centrado2 =""
                      this.reto =""
                     this.reto2 =""
                     this.parrafo =""
                     this.parrafo2 =""
                     this.image=""
                     this.name_imagen=""
                      this.image2=""
                     this.name_imagen2=""
                }else{
                      this.status='error'
                    this.message='Una de las cajas esta vacia.'
                   
                    this.delStatus()
                }


            }else{
                this.status='error'
                this.message='Selecciona un tipo de bloque.'
                this.delStatus()
            }
        },






   }


}
</script>
<style scoped>
.mlf{
    margin-left: 1vw;
}
.mtp{
    margin-top: 3vw;
}
.opciones-bloque{
    min-height: 20vw;
}

/* QUILL EDITOR */
.quill-editor , .quill-editor-imagen , .quill-editor-video {
  margin-bottom: 3vw;
  margin-top: 1vw;
  
}
.quill-editor{
  width: 53.65vw;
}
.quill-editor-imagen, .quill-editor-video{
    width: 27.65vw;
}

.tbl1, .tbl2{
    width: 20vw;
   
    margin-right: 1vw;
}

p.et1{
    width: 15vw;
    cursor: default;
}
p.elim{
     cursor: pointer;
     color: var(--color-3) !important;
 
    
}

.bodytbl{
    height: 10vw;
    overflow: auto;
    padding: 1vw;
     background: var(--color-2);
}
p.etiqueta{
    color: var(--color-5);
   
    font-size: 0.6VW;
}

.hdreti{
    background:  var(--color-1);
    padding: .5vw 1vw;
    color:  var(--color-5);
    font-weight: 600;
    font-size: 0.8VW;
}


    
    #EditBlo label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #EditBlo input, #EditBlo select{
        height: 2.5VW;
        width: 13.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw 1.5vw 1.5vw 0vw;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
        padding-right: 1vw;
        margin-right:2vw;
    }


#select-role {
  height: 2.5vw;
  width: 14.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}#select-ch:checked ~ .options-cont {
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 12.3vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}#select-ch:checked ~ .options-cont , #select-ch2:checked ~ .options-cont, #select-ch3:checked ~ .options-cont , #select-ch4:checked ~ .options-cont , #select-ch5:checked ~ .options-cont  , #select-ch6:checked ~ .options-cont , #select-ch7:checked ~ .options-cont{
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 13.32vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}
   
.buscador input {
    width: 15.312500000000002VW;
    border: none !important;
    border-radius: none !important;
    height: 1.5625VW !important;
    color: #8D8D8D;
}
.mtbtn{
        margin-left: 1vw;
    margin-top: 1.4vw;
}

.optionsbl{
    margin-top: 1vw;
}

.col1{
 
     
    width: 5vw;
    text-align: center;
   
}
.col2{

     
      width: 5vw;
    text-align: center;
}
.col3{
   
     overflow: auto;
    width: 56vw;
    
}
.cl3t{
    text-align: center;
}

.col3 p{
    padding-left: 1vw;
}
.col4{
   
    
      width: 5vw;
    text-align: center;
}


.header-bc p{
    margin-right: 1px ;
     color: aliceblue;
    background: var(--color-2);
    margin-bottom: 0vw;
    font-weight: 600;
    padding: 0.5vw 0vw;
    
}

.bloq-item{
  border-bottom: .2vw solid var(--color-4);
   color: var(--color-4);
  
}

.bloq-item h2:hover{
    color: var(--color-1);
}

.bloques-cont{
    width: 71vw;
    margin-bottom: 5vw;
}

h2.cp{
    cursor: pointer;
}
.caja1, .caja2{
    width: 50%;
}

.col3 img{
        width: 72%;
    padding: 0.5vw 3.5vw;
}

img.imagen_portada{
    width: 20vw;
}



p.btnStatus{
     cursor: pointer;
    border: none;
    background: var(--color-4);
    color: var(--color-5);
    width: 6VW;
    text-align: center;
    padding: .5vw 0vw;
    margin-right: 1vw;
    border-radius: 1.5vw;
    margin-top: 0.7291666666666666VW;
    font-weight: 600;
    font-size: 0.7291666666666666VW;
}

.statusVisible{
     background: var(--color-1) !important;
}
.btnorden p{
       cursor: pointer;
    border: none;
    background: var(--color-4);
    color: var(--color-5);
    width: 4vw;
    text-align: center;
    transition: all 300ms;
    margin-right: 0.1vw;
    font-weight: 500;
    font-size: 0.7291666666666666VW;
    padding-bottom: 0.2vw;
    padding-top: 0.2vw;
    
}

.btnorden p:first-child{
  border-radius: 1.5vw 0vw 0vw 1.5vw;
}
.btnorden p:last-child{
  border-radius: 0vw  1.5vw 1.5vw 0vw ;
}
.btnorden p:hover{
    background: var(--color-2);
}

.col3 iframe{
    padding: 0.5vw 3.5vw;
}
 

    
</style>