import { render, staticRenderFns } from "./BlogC.vue?vue&type=template&id=5586766a&scoped=true&"
import script from "./BlogC.vue?vue&type=script&lang=js&"
export * from "./BlogC.vue?vue&type=script&lang=js&"
import style0 from "./BlogC.vue?vue&type=style&index=0&id=5586766a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5586766a",
  null
  
)

export default component.exports