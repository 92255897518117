<template>
    <div id="Sections">
         <HeaderSec /> 
         <div v-if="historyOption == 'Usuarios'">
             <Usuarios/>
         </div>
            <div v-if="historyOption == 'Personalizacion'">
             <Perfil/>
         </div>

            <div v-if="historyOption == 'category'">
             <Category/>
         </div>
   <div v-if="historyOption == 'project_category'">
             <ProjectCategory/>
         </div>
<div v-if="historyOption == 'project'">
             <Project/>
         </div>
        
               <div v-if="historyOption == 'colaboradores'">
             <Colaboradores/>
         </div>
  <div v-if="historyOption == 'BLOG'">
            <Blog />
         </div>

               <div v-if="historyOption == 'tag'">
             <Tag/>
         </div>



        
    </div>
</template>
<script>

import {mapActions} from 'vuex';

import HeaderSec from '../components/HeaderSec.vue';
import Usuarios from '../views/usuarios/usuarios.vue';
import Perfil from '../views/profile/profile.vue';
import Category from '../views/category/category.vue';
import Colaboradores from '../views/colaboradores/colaboradores.vue';
import Tag  from '../views/tag/tag.vue';
import Blog from '../views/blog/BlogC.vue';
import ProjectCategory from '../views/category_project/category.vue';
import Project from '../views/project/project.vue';


export default {
  name: 'Sections',
components:{
      HeaderSec,
      Usuarios,
      Perfil,
      Category,
      Colaboradores,
      Tag,
      Blog,
      ProjectCategory,
      Project,
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
        ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width:  73.177083VW;
     margin-left: 4.166666666666666VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>
